








import {
  defineComponent,
} from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'LoaderFrame',
  props: {
    transparentPercent: {
      type: Number,
      default: 80
    },
    loading: {
      type: Boolean
    },
    abs: {
      type: Boolean,
      default: true
    },
    fixed: {
      type: Boolean,
      default: false
    }
  }
});
















import LoginForm from '~/components/Palmers/Forms/LoginForm.vue';

import {
  computed,
  defineComponent, onMounted, ref, useContext, useRoute,
} from '@nuxtjs/composition-api';
import {
  FormName,
} from "~/modules/customer/components/LoginModal/forms/types";
import {LoginFormFields} from '~/components/Palmers/Forms/Login/types';
import {useCart, useUiState, useUser, useWishlist} from "~/composables";
import {useNotifications, useUrl} from "~/composables/Palmers";
import {getMetaInfoVuePages} from "~/helpers/getMetaInfo";

export default defineComponent({
  name: 'Login',
  components: {
    LoginForm
  },
  props: {
    isCheckout: {
      type: Boolean,
      default() {
        return false;
      }
    }
  },
  middleware: 'is-authenticated',
  setup(props) {
    const {isLoginModalOpen} = useUiState();

    const {app} = useContext();
    const route = useRoute();

    const {sendMessage} = useNotifications();

    const {getPageUrl} = useUrl();

    const customLoading = ref(false);


    const {
      load,
      login,
      loading,
      isAuthenticated,
      error: userError,
    } = useUser();

    const {load: loadCart} = useCart();
    const {loadItemsCount} = useWishlist();

    const currentlyDisplayedForm = ref<FormName>('login');
    const barTitle = computed(() => {
      const mapFormNameToTopBarLabel: Record<FormName, string> = {
        login: 'Sign in',
        register: 'Register',
        forgotPassword: 'Reset Password',
        forgotPasswordThankYou: 'Thank you',
      };

      return mapFormNameToTopBarLabel[currentlyDisplayedForm.value];
    });

    const loginForm = ref<LoginFormFields>({
      email: '',
      password: '',
      logged_in: false,
    });

    // @ts-expect-error Recaptcha is not registered as a Nuxt module. Its absence is handled in the code
    const {$recaptcha, $config} = useContext();
    const isRecaptchaEnabled = ref<boolean>(typeof $recaptcha !== 'undefined' && Boolean($config.isRecaptcha));

    const getRecaptchaInfo = async (isRecaptchaOn: boolean): Promise<{ token: string | null, cleanup: () => void }> => {
      if (isRecaptchaOn) {
        $recaptcha.init();
        return {
          token: await $recaptcha.getResponse(), cleanup: () => {
            $recaptcha.reset();
          }
        };
      }
      return {
        token: null, cleanup: () => {
        }
      };
    };

    const onLoginFormSubmit = async (form: LoginFormFields) => {
      customLoading.value = true;
      loginForm.value = form;
      const {token, cleanup} = await getRecaptchaInfo(isRecaptchaEnabled.value);
      await login({user: {...form, ...(token ? {recaptchaToken: token} : {})}});
      cleanup();
      if (!userError.value.login) {
        await Promise.all(
          [
            load(),
            loadItemsCount(),
            loadCart()
          ]);

        customLoading.value = false;

        if(props.isCheckout) {
          await app.router.push(getPageUrl({name: 'shipping-methods'}));
        }else {
          await goToMyAccount();
        }
      }
      customLoading.value = false;
    };

    const goToMyAccount = async () => {
      await app.router.push(getPageUrl({name: 'customer-overview'}));
    }

    onMounted(() => {
      if(route.value?.query?.status && route.value?.query?.message) {
        if(route.value.query.status !== 'error') return;
        sendMessage('Customer login error', route.value.query.message);
      }
    })

    return {
      isLoginModalOpen,
      userError,
      loading,
      barTitle,
      loginForm,
      isRecaptchaEnabled,
      isAuthenticated,
      onLoginFormSubmit,
      customLoading,
      meta: getMetaInfoVuePages({
        title: 'Login'
      }),
      sendMessage
    };
  },
  head() {
    return this.meta;
  },
  mounted() {
    this.$nextTick(() => {
      if(this.$route.name.indexOf('account-confirm') > -1) {
        this.sendMessage('Success confirm account', 'Account verified. You can try to login', false);
      }
    })
  }
});

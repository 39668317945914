
























































































































import {
  computed,
  defineComponent, onMounted,
  ref, useAsync,
  useContext,
  useRoute,
  watch
} from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import { required, email } from 'vee-validate/dist/rules';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import {
  SfButton,
  SfLoader,
} from '@storefront-ui/vue';
import Checkbox from '~/components/Palmers/Forms/Checkbox.vue';
import Input from '~/components/Palmers/Input/Input.vue';
import { LoginFormFields, FormName } from '~/components/Palmers/Forms/Login/types';
import loadSocialsData from "~/customQueries/queries/loadSocialsContent.gql";
import {useUrl} from "~/composables/Palmers";
import LoaderFrame from "~/components/Palmers/BaseComponents/Loaders/LoaderFrame.vue";
import {getItem, removeItem, setItem} from "~/helpers/asyncLocalStorage";
extend('email', {
  ...email,
  message: 'Invalid email',
});

extend('required', {
  ...required,
  message: 'This field is required',
});

export default defineComponent({
  components: {
    Input,
    SfButton,
    SfLoader,
    Checkbox,
    ValidationObserver,
    ValidationProvider,
    LoaderFrame
  },
  props: {
    form: {
      type: Object as PropType<LoginFormFields>,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    showRecaptcha: {
      type: Boolean,
      default: false,
    },
    showLabel: {
      type: Boolean,
      default: false
    },
    isCheckout: {
      type: Boolean,
      default() {
        return false;
      }
    }
  },
  setup(props, { emit }) {
    const route = useRoute();
    const socials = ref(<Object |[]>false);
    const loaded = ref(false);
    const { $vsf, app } = useContext();
    const {getPageUrl} = useUrl();
    const customLoading = ref(false);

    const registerQuery = route.value?.query?.register;

    const {
      query: {page},
    } = route.value;

    const formCopy = ref<LoginFormFields>();
    watch(() => props.form, (newForm) => { formCopy.value = { ...newForm }; }, { immediate: true, deep: true });
    const changeForm = (formToNavigateTo: FormName) => {
      emit('change-form', formToNavigateTo);
    };

    const socialsData = computed(() => (socials.value) as Object);

    useAsync(async () => {
      //@ts-ignore
      const {data:socialData} = await $vsf.$magento.api.loadSocialsData();

      socials.value = socialData?.['socials'] as Object;

      loaded.value = true;
    });

    onMounted(async () => {
      const isCheckoutSso = await getItem('sso-checkout');
      if (isCheckoutSso) {
        await removeItem('sso-checkout');
        window.location.href = getPageUrl({name: 'shipping-methods'});
      }
    })

    return {
      customLoading,
      registerQuery,
      socialsData,
      formCopy,
      changeForm,
      getPageUrl
    };
  },
  methods: {
    async ssoClick(url) {
      if(this.isCheckout) {
        await setItem('sso-checkout', 'true');
      }

      this.customLoading = true;

      setTimeout(() => {
        this.customLoading = false;
        window.location.href = url;
      }, 2000)

    }
  }
});
